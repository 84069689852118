import {Suspense} from 'react'
import {Outlet} from 'react-router-dom'
import {I18nProvider} from '../_metronic/i18n/i18nProvider'
import {LayoutProvider, LayoutSplashScreen} from '../_metronic/layout/core'
import {MasterInit} from '../_metronic/layout/MasterInit'
import AuthInit from './modules/auth/redux/AuthInit'
import AdapterDateFns from '@mui/lab/AdapterDateFns'
import LocalizationProvider from '@mui/lab/LocalizationProvider'
import {de, enUS} from 'date-fns/locale'

import {QueryClient, QueryClientProvider} from 'react-query'
import {useLang} from '../_metronic/i18n/Metronici18n'

const queryClient = new QueryClient()

const App = () => {
  const locale = useLang()

  return (
    <Suspense fallback={<LayoutSplashScreen />}>
      <QueryClientProvider client={queryClient}>
        <I18nProvider>
          <LocalizationProvider dateAdapter={AdapterDateFns} locale={locale === 'en' ? enUS : de}>
            <LayoutProvider>
              <AuthInit>
                <Outlet />
                <MasterInit />
              </AuthInit>
            </LayoutProvider>
          </LocalizationProvider>
        </I18nProvider>
      </QueryClientProvider>
    </Suspense>
  )
}

export {App}
