/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {useState} from 'react'
import {Form, Formik} from 'formik'
import {profileDetailsSchema, initialUser} from '../../../pages/_INITIALS'
import {Navigate} from 'react-router-dom'
import {useIntl} from 'react-intl'
import {errorSwal} from '../../../pages/_components/swal'
import {ProfileDetails} from '../../accounts/components/settings/cards/ProfileDetails'
import {registerUserUpdate} from '../../../pages/_CRUD'
import {LoadSpan} from '../../../pages/_components/spans'
import {LinkContainer} from 'react-router-bootstrap'

export function Registration() {
  const intl = useIntl()
  const [loading, setLoading] = useState(false)
  const [cont, setContinue] = useState(false)

  return (
    <>
      <Formik
        initialValues={{...initialUser, country: 'GER'}}
        validationSchema={profileDetailsSchema(intl)}
        onSubmit={(values) => {
          setLoading(true)
          registerUserUpdate(values)
            .then((res) => {
              setContinue(true)
            })
            .catch((e) => errorSwal(e)) //TODO if server return 500. No exception is thrown. Bug?
            .finally(() => setLoading(false))
        }}
      >
        <Form>
          <ProfileDetails />
          <div className='d-flex flex-wrap justify-content-center'>
            <LinkContainer to='/auth/login'>
              <button
                type='button'
                id='kt_login_password_reset_form_cancel_button'
                className='btn btn-lg btn-light-primary fw-bolder flex-fill'
              >
                {intl.formatMessage({id: 'GENERAL.BACK'})}
              </button>
            </LinkContainer>
            <div style={{width: '1em'}}></div>
            <button
              type='submit'
              id='kt_password_reset_submit'
              className='btn btn-lg btn-primary fw-bolder me-4 flex-fill'
              disabled={loading}
            >
              {cont && <Navigate to='/auth/pin-input'></Navigate>}
              {!loading && (
                <span className='indicator-label'>
                  {intl.formatMessage({id: 'GENERAL.CONTINUE'})}
                </span>
              )}
              {loading && <LoadSpan />}
            </button>
          </div>
        </Form>
      </Formik>
    </>
  )
}
