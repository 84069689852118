import React, {FC} from 'react'
import {useIntl} from 'react-intl'
import {PageTitle} from '../../../_metronic/layout/core'
import ExtraInfoPage from './extra.page'

const ExtraInfoPageWrapper: FC = () => {
  const intl = useIntl()
  return (
    <>
      <PageTitle breadcrumbs={[]}>{intl.formatMessage({id: 'MENU.EXTRAINFO'})}</PageTitle>
      <ExtraInfoPage />
    </>
  )
}

export default ExtraInfoPageWrapper
