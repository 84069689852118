import React, {useState} from 'react'
import Uppy from '@uppy/core'
// import Dashboard from '@uppy/dashboard'
import ImageEditor from '@uppy/image-editor'
import XHRUpload from '@uppy/xhr-upload'
import {DashboardModal} from '@uppy/react'
import * as auth from '../../modules/auth/redux/AuthRedux'
import {useDispatch} from 'react-redux'

import '@uppy/core/dist/style.css'
import '@uppy/dashboard/dist/style.css'
import '@uppy/image-editor/dist/style.css'
import {toAbsoluteUrl} from '../../../_metronic/helpers'
import {USER_SET_PIC} from '../_CRUD'
import {useLang} from '../../../_metronic/i18n/Metronici18n'

const de_DE = require('@uppy/locales/lib/de_DE')
const en_US = require('@uppy/locales/lib/en_US')

export const AvatarPicker = ({user}: any) => {
  const language = useLang()

  const [modal_show, toggleModal] = useState(false)
  const dispatch = useDispatch()

  const uppy = new Uppy({
    meta: {type: 'avatar'},
    restrictions: {maxNumberOfFiles: 1},
    //   autoProceed: true,
    locale: language === 'de' ? de_DE : en_US,
  })

  uppy.use(ImageEditor, {
    id: 'ImageEditor',
    quality: 0.8,
    cropperOptions: {
      // viewMode: 0,
      background: true,
      autoCrop: true,
      autoCropArea: 1,
      responsive: true,
      croppedCanvasOptions: {},
      aspectRatio: 1,
    },
    actions: {
      revert: true,
      rotate: true,
      granularRotate: true,
      flip: true,
      zoomIn: true,
      zoomOut: true,
      cropSquare: false,
      cropWidescreen: false,
      cropWidescreenVertical: false,
    },
  })

  uppy.use(XHRUpload, {endpoint: USER_SET_PIC, formData: true, fieldName: 'pic'})

  uppy.on('error', (error) => {
    console.log(error)
  })

  uppy.on('complete', (result) => {
    if (result.successful[0].response) {
      let url = result.successful[0].response.body
      dispatch(auth.actions.setUser({...user, pic: url as any}))
    }
  })

  return (
    <div>
      <div
        className='image-input image-input-outline'
        data-kt-image-input='true'
        style={{backgroundImage: `url(${toAbsoluteUrl('/media/avatars/blank.png')})`}}
      >
        <div
          className='image-input-wrapper w-125px h-125px'
          style={{
            backgroundImage: `url(${
              user.pic ? toAbsoluteUrl(user.pic) : toAbsoluteUrl('/media/avatars/blank.png')
            })`,
            cursor: 'pointer',
          }}
          onClick={() => toggleModal(true)}
        ></div>
      </div>

      <DashboardModal
        uppy={uppy}
        plugins={['ImageEditor']}
        open={modal_show}
        onRequestClose={() => toggleModal(false)}
        locale={{
          strings: {
            // Text to show on the droppable area.
            // Used as the label for the link that opens the system file selection dialog.
            poweredBy: '',
          },
        }}
      />
    </div>
  )
}
