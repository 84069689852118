import {Route, Routes, Navigate} from 'react-router-dom'
import {MasterLayout} from '../../_metronic/layout/MasterLayout'
// import ApplicationPageWrapper from '../pages/application/application.wrapper'
import ExtraInfoPageWrapper from '../pages/extra/extra.wrapper'
// import TopBarProgress from 'react-topbar-progress-indicator'
// import {MenuTestPage} from '../pages/MenuTestPage'
import ProfilePageWrapper from '../pages/profile/profile.wrapper'
import StartPageWrapper from '../pages/start/start.wrapper'
// import {getCSSVariableValue} from '../../_metronic/assets/ts/_utils'

const PrivateRoutes = () => {
  // const ProfilePage = lazy(() => import('../modules/profile/ProfilePage'))

  return (
    <Routes>
      <Route element={<MasterLayout />}>
        {/* Redirect to Dashboard after success login/registartion */}
        <Route path='auth/*' element={<Navigate to='/start' />} />

        {/* Pages */}
        <Route path='start' element={<StartPageWrapper />} />
        <Route path='profile' element={<ProfilePageWrapper />} />
        <Route path='extra' element={<ExtraInfoPageWrapper />} />
        {/* <Route path='application' element={<ApplicationPageWrapper />} /> */}

        {/* Page Not Found */}
        <Route path='*' element={<Navigate to='/error/404' />} />
      </Route>
    </Routes>
  )
}

// const SuspensedView: FC = ({children}) => {
//   const baseColor = getCSSVariableValue('--bs-primary')
//   TopBarProgress.config({
//     barColors: {
//       '0': baseColor,
//     },
//     barThickness: 1,
//     shadowBlur: 5,
//   })
//   return <Suspense fallback={<TopBarProgress />}>{children}</Suspense>
// }

export {PrivateRoutes}
