import {Field, useFormikContext} from 'formik'
import {FC} from 'react'
import {useIntl} from 'react-intl'
import {useQuery} from 'react-query'
import {toAbsoluteUrl} from '../../../../../../_metronic/helpers'
import {
  CustomBaseFormikInput,
  CustomCheckGroup,
  CustomDatePicker,
} from '../../../../../pages/_components/inputs'
import {CustomSeparator} from '../../../../../pages/_components/separators'
import {getCountries} from '../../../../../pages/_CRUD'

const ProfileDetails: FC = () => {
  const intl = useIntl()

  const formik = useFormikContext()

  const baseLanguages = [
    {
      label: intl.formatMessage({id: 'TRANSLATOR.LANGUAGE.GERMAN'}),
      value: 'Deutsch',
    },
    {
      label: intl.formatMessage({id: 'TRANSLATOR.LANGUAGE.ENGLISH'}),
      value: 'Englisch',
    },
  ]

  const languages = [
    ...baseLanguages,
    {
      label: intl.formatMessage({id: 'TRANSLATOR.LANGUAGE.FRENCH'}),
      value: 'Französisch',
    },
    {
      label: intl.formatMessage({id: 'TRANSLATOR.LANGUAGE.ITALIAN'}),
      value: 'Italienisch',
    },
    {
      label: intl.formatMessage({id: 'TRANSLATOR.LANGUAGE.CROATIAN'}),
      value: 'Kroatisch',
    },
    {
      label: intl.formatMessage({id: 'TRANSLATOR.LANGUAGE.DUTCH'}),
      value: 'Niederländisch',
    },
    {
      label: intl.formatMessage({id: 'TRANSLATOR.LANGUAGE.POLISH'}),
      value: 'Polnisch',
    },
    {
      label: intl.formatMessage({id: 'TRANSLATOR.LANGUAGE.PORTUGUESE'}),
      value: 'Portugiesisch',
    },
    {
      label: intl.formatMessage({id: 'TRANSLATOR.LANGUAGE.RUSSIAN'}),
      value: 'Russisch',
    },
    {
      label: intl.formatMessage({id: 'TRANSLATOR.LANGUAGE.SERBIAN'}),
      value: 'Serbisch',
    },
    {
      label: intl.formatMessage({id: 'TRANSLATOR.LANGUAGE.SLOVAK'}),
      value: 'Slowakisch',
    },
    {
      label: intl.formatMessage({id: 'TRANSLATOR.LANGUAGE.SLOVENIAN'}),
      value: 'Slowenisch',
    },
    {
      label: intl.formatMessage({id: 'TRANSLATOR.LANGUAGE.SPANISH'}),
      value: 'Spanisch',
    },
    {
      label: intl.formatMessage({id: 'TRANSLATOR.LANGUAGE.CZECH'}),
      value: 'Tschechisch',
    },
    {
      label: intl.formatMessage({id: 'TRANSLATOR.LANGUAGE.TURKISH'}),
      value: 'Türkisch',
    },
    {
      label: intl.formatMessage({id: 'TRANSLATOR.LANGUAGE.HUNGARIAN'}),
      value: 'Ungarisch',
    },
  ]

  const laender = useQuery(
    'laender',
    () => getCountries().then((res) => res.data),
    {staleTime: 1000 * 60 * (10 + 0)} // 10 min in ms
  )

  return (
    <>
      <div className='row mx-4'>
        <div className='col-md-2 col-12 mb-6'>
          <Field
            name='salutation'
            label={intl.formatMessage({id: 'GENERAL.SALUTATION'})}
            tag='select'
            component={CustomBaseFormikInput}
            required={true}
          >
            <option value=''>{intl.formatMessage({id: 'REQUEST.EMPTY_SELECT'})}</option>
            <option value='M'>{intl.formatMessage({id: 'GENERAL.SALUTATION.M'})}</option>
            <option value='F'>{intl.formatMessage({id: 'GENERAL.SALUTATION.F'})}</option>
          </Field>
        </div>

        <div className='col-md-5 col-12 mb-6'>
          <Field
            name='first_name'
            label={intl.formatMessage({id: 'GENERAL.FNAME'})}
            required={true}
            component={CustomBaseFormikInput}
          />
        </div>

        <div className='col-md-5 col-12 mb-6'>
          <Field
            label={intl.formatMessage({id: 'GENERAL.LNAME'})}
            name='last_name'
            required={true}
            component={CustomBaseFormikInput}
          />
        </div>

        <div className='col-md-4 mb-6'>
          <CustomDatePicker
            name='birthday'
            label={intl.formatMessage({id: 'GENERAL.BIRTHDAY'})}
            required={true}
          />
        </div>

        <div className='col-md-4 mb-6'>
          <Field
            name='job'
            label={intl.formatMessage({id: 'GENERAL.JOB'})}
            component={CustomBaseFormikInput}
          />
        </div>

        <div className='col-md-4 mb-6'>
          <Field
            name='education'
            label={intl.formatMessage({id: 'GENERAL.EDUCATION'})}
            component={CustomBaseFormikInput}
            tag='select'
          >
            <option value=''>{intl.formatMessage({id: 'REQUEST.EMPTY_SELECT'})}</option>
            <option value='Promotion'>
              {intl.formatMessage({id: 'GENERAL.EDUCATION.PROMOTION'})}
            </option>
            <option value='Master'>{intl.formatMessage({id: 'GENERAL.EDUCATION.MASTER'})}</option>
            <option value='Bachelor'>
              {intl.formatMessage({id: 'GENERAL.EDUCATION.BACHELOR'})}
            </option>
            <option value='Abitur'>{intl.formatMessage({id: 'GENERAL.EDUCATION.SCHOOL.0'})}</option>
            <option value='Fachhochschulreife'>
              {intl.formatMessage({id: 'GENERAL.EDUCATION.SCHOOL.1'})}
            </option>
            <option value='Mittlerer Schulabschluss'>
              {intl.formatMessage({id: 'GENERAL.EDUCATION.SCHOOL.2'})}
            </option>
            <option value='Hauptschulabschluss'>
              {intl.formatMessage({id: 'GENERAL.EDUCATION.SCHOOL.3'})}
            </option>
            <option value='qualifizierender Hauptschulabschluss'>
              {intl.formatMessage({id: 'GENERAL.EDUCATION.SCHOOL.4'})}
            </option>
          </Field>
        </div>
      </div>

      <CustomSeparator title={intl.formatMessage({id: 'GENERAL.CONTACT'})} />

      <div className='row mx-4'>
        <div className='col-md-4 mb-6'>
          <Field
            label={intl.formatMessage({id: 'GENERAL.CONTACT.EMAIL'})}
            name='email'
            required={true}
            component={CustomBaseFormikInput}
          />
        </div>

        <div className='col-md-4 mb-6'>
          <Field
            label={intl.formatMessage({id: 'GENERAL.CONTACT.MOBILEPHONE'})}
            name='mobilePhone'
            type='tel'
            component={CustomBaseFormikInput}
            required={true}
          />
        </div>

        <div className='col-md-4 mb-6'>
          <Field
            label={intl.formatMessage({id: 'GENERAL.CONTACT.PHONE'})}
            name='phone'
            type='tel'
            component={CustomBaseFormikInput}
          />
        </div>
      </div>

      <CustomSeparator title={intl.formatMessage({id: 'GENERAL.ADDRESS'})} />

      <div className='row mx-4'>
        <div className='col-md-6 mb-6'>
          <Field
            label={intl.formatMessage({id: 'GENERAL.ADDRESS.STREET'})}
            name='street'
            required={true}
            component={CustomBaseFormikInput}
          />
        </div>

        <div className='col-md-3 col-12 mb-6'>
          <Field
            label={intl.formatMessage({id: 'GENERAL.ADDRESS.ZIPCODE'})}
            name='zip'
            required={true}
            component={CustomBaseFormikInput}
          />
        </div>

        <div className='col-md-3 col-12 mb-6'>
          <Field
            label={intl.formatMessage({id: 'GENERAL.ADDRESS.STATE'})}
            name='state'
            required={true}
            component={CustomBaseFormikInput}
          />
        </div>

        <div className='col-md-6 mb-6'>
          <Field
            label={intl.formatMessage({id: 'GENERAL.ADDRESS.COUNTRY'})}
            name='country'
            required={true}
            isLoading={laender.isLoading}
            component={CustomBaseFormikInput}
            tag='react-select'
            options={laender.data?.map((el) => ({
              value: el.ioc,
              label: `${el.ioc} - ${el.name}`,
            }))}
          />
        </div>
      </div>

      <CustomSeparator
        title={
          intl.formatMessage({id: 'PROFILE.NATIONALITY'}) +
          ' / ' +
          intl.formatMessage({id: 'TRANSLATOR.LANGUAGE'})
        }
      />

      <div className='row mx-4'>
        <div className='col-md-4'>
          <div className='mb-6'>
            <Field
              label={intl.formatMessage({id: 'PROFILE.NATIONALITY'})}
              name='nationality'
              required={true}
              isLoading={laender.isLoading}
              component={CustomBaseFormikInput}
              tag='react-select'
              options={laender.data?.map((el) => ({
                value: el.ioc,
                label: `${el.ioc} - ${el.name}`,
              }))}
            />
          </div>

          <div className='mb-6'>
            <CustomCheckGroup
              tag='radio'
              required={true}
              name='lang_communication'
              label={intl.formatMessage({id: 'PROFILE.LANGUAGE.PREFERENCE'})}
              options={baseLanguages}
            />
          </div>
        </div>

        <div className='col-md-4 mb-6'>
          <CustomCheckGroup
            tag='checkbox'
            required={true}
            name='languages'
            label={intl.formatMessage({id: 'PROFILE.LANGUAGE.SPOKEN'})}
            options={languages}
          />
        </div>

        <div className='col-md-4 mb-6'>
          {languages.map((el) =>
            (formik.values as any).languages.indexOf(el.value) === -1 ? (
              <></>
            ) : (
              <div className='mb-6'>
                <Field
                  name={'lang_levels.' + el.value}
                  label={intl.formatMessage({id: 'GENERAL.LANGUAGE.NIVEAU'}) + ' ' + el.label}
                  component={CustomBaseFormikInput}
                  tag='select'
                >
                  <option value=''>{intl.formatMessage({id: 'REQUEST.EMPTY_SELECT'})}</option>
                  <option value='Muttersprache'>
                    {intl.formatMessage({id: 'GENERAL.LANGUAGE.NIVEAU.MOTHERTONGUE'})}
                  </option>
                  <option value='verhandlungssicher'>
                    {intl.formatMessage({id: 'GENERAL.LANGUAGE.NIVEAU.EXCELLENT'})}
                  </option>
                  <option value='fließend'>
                    {intl.formatMessage({id: 'GENERAL.LANGUAGE.NIVEAU.FLUENT'})}
                  </option>
                  <option value='Grundkenntnisse'>
                    {intl.formatMessage({id: 'GENERAL.LANGUAGE.NIVEAU.BASIC'})}
                  </option>
                </Field>
              </div>
            )
          )}
        </div>
      </div>

      <CustomSeparator title={intl.formatMessage({id: 'PROFILE.EMERGENCY'})} />

      <div className='row mx-4'>
        <div className='col-md-6 mb-6'>
          <Field
            label={intl.formatMessage({id: 'GENERAL.NAME'})}
            name='emergency_name'
            component={CustomBaseFormikInput}
          />
        </div>

        <div className='col-md-3 mb-6'>
          <Field
            name='emergency_relation'
            label={intl.formatMessage({id: 'PROFILE.EMERGENCY.RELATION'})}
            tag='select'
            component={CustomBaseFormikInput}
          >
            <option value=''>{intl.formatMessage({id: 'REQUEST.EMPTY_SELECT'})}</option>
            <option value='Vater'>
              {intl.formatMessage({id: 'PROFILE.EMERGENCY.SELECT.PARENT.M'})}
            </option>
            <option value='Mutter'>
              {intl.formatMessage({id: 'PROFILE.EMERGENCY.SELECT.PARENT.F'})}
            </option>
            <option value='Freund:in'>
              {intl.formatMessage({id: 'PROFILE.EMERGENCY.SELECT.PARTNER'})}
            </option>
            <option value='Ehefrau'>
              {intl.formatMessage({id: 'PROFILE.EMERGENCY.SELECT.SPOUSE.M'})}
            </option>
            <option value='Ehemann'>
              {intl.formatMessage({id: 'PROFILE.EMERGENCY.SELECT.SPOUSE.F'})}
            </option>
          </Field>
        </div>

        <div className='col-md-3 mb-6'>
          <Field
            label={intl.formatMessage({id: 'GENERAL.CONTACT.PHONE'})}
            name='emergency_phone'
            component={CustomBaseFormikInput}
          />
        </div>
      </div>

      <CustomSeparator title={intl.formatMessage({id: 'PROFILE.CONDITIONS'})} />

      <div className='row mx-4'>
        <div className='col-md-12 mb-6'>
          <CustomCheckGroup
            tag='checkbox'
            required={true}
            label={intl.formatMessage({id: 'PROFILE.CONDITIONS'})}
            name='conditions'
            options={[
              {
                label: intl.formatMessage(
                  {id: 'PROFILE.CONDITIONS.ACCEPT'},
                  {
                    agb: (
                      <a
                        href={toAbsoluteUrl('/media/_mstart/VEITL_Nutzungsbedingungen_DE_EN.pdf')}
                        target='_blank'
                        rel='noreferrer'
                      >
                        {intl.formatMessage({id: 'PROFILE.CONDITIONS'})}
                      </a>
                    ),
                  }
                ),
                value: 'accept',
              },
            ]}
          />
        </div>
      </div>
    </>
  )
}

export {ProfileDetails}
