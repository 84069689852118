import {useIntl} from 'react-intl'

export const LoadSpan = () => {
  const intl = useIntl()

  return (
    <div className='d-flex justify-content-center align-items-center'>
      <span className='spinner-border spinner-border-sm align-middle mx-2' />
      <span>{intl.formatMessage({id: 'GENERAL.LOADING'})}</span>
    </div>
  )
}
