import axios from 'axios'
import {CLUB, COUNTRY, EVENT, JOB} from './_TYPES'
import {UserModel} from '../modules/auth/models/UserModel'

const API_BASE = '/api'
const META_BASE = API_BASE + '/meta'
const USER_BASE = API_BASE + '/user'
const AUTH_BASE = API_BASE + '/auth'
const EVENT_BASE = API_BASE + '/events'

// ===========================================================

const META_COUNTRIES = META_BASE + '/countries'
const META_CLUBS = META_BASE + '/clubs'

const USER_JOBS = USER_BASE + '/jobs'
const USER_PREF = USER_BASE + '/pref'
const USER_SET_PREF = USER_BASE + '/set-pref'
export const USER_SET_PIC = USER_BASE + '/set-pic'

const AUTH_REGISTER = AUTH_BASE + '/register'

export const getCountries = () => {
  return axios.get<COUNTRY[]>(META_COUNTRIES)
}

export const getClubs = () => {
  return axios.get<CLUB[]>(META_CLUBS)
}

export const registerUserUpdate = (values: UserModel) => {
  return axios.post(AUTH_REGISTER, values)
}

export const postUserUpdate = (values: UserModel) => {
  return axios.post(USER_BASE, values)
}

export const getEvents = () => {
  return axios.get<EVENT[]>(EVENT_BASE)
}

export const getCheckedEvents = (id_event: string) => {
  return axios.get(USER_PREF + `/${id_event}`)
}

export const setCheckedEvents = (id_event: string, checked: any) => {
  return axios.post(USER_SET_PREF + `/${id_event}`, checked)
}

export const getJobs = () => {
  return axios.get<JOB[]>(USER_JOBS)
}
