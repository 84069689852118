import * as Yup from 'yup'

// const phoneRegExp =
//   /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/

export const profileDetailsSchema = (intl: any) =>
  Yup.object().shape({
    salutation: Yup.string().required(intl.formatMessage({id: 'ERROR.REQUIRED'})),
    first_name: Yup.string().required(intl.formatMessage({id: 'ERROR.REQUIRED'})),
    last_name: Yup.string().required(intl.formatMessage({id: 'ERROR.REQUIRED'})),
    street: Yup.string().required(intl.formatMessage({id: 'ERROR.REQUIRED'})),
    zip: Yup.string().required(intl.formatMessage({id: 'ERROR.REQUIRED'})),
    state: Yup.string().required(intl.formatMessage({id: 'ERROR.REQUIRED'})),
    country: Yup.string().required(intl.formatMessage({id: 'ERROR.REQUIRED'})),
    email: Yup.string()
      .email(intl.formatMessage({id: 'ERROR.INVALID_INPUT'}))
      .required(intl.formatMessage({id: 'ERROR.REQUIRED'})),
    phone: Yup.string().optional(), //.matches(phoneRegExp, intl.formatMessage({id: "ERROR.INVALID_INPUT"})),
    mobilePhone: Yup.string().required(intl.formatMessage({id: 'ERROR.REQUIRED'})), //.matches(phoneRegExp, intl.formatMessage({id: "ERROR.INVALID_INPUT"})),
    birthday: Yup.date().required(intl.formatMessage({id: 'ERROR.REQUIRED'})),
    languages: Yup.array()
      .min(1, intl.formatMessage({id: 'ERROR.REQUIRED'}))
      .required(intl.formatMessage({id: 'ERROR.REQUIRED'})),
    lang_communication: Yup.string().required(intl.formatMessage({id: 'ERROR.REQUIRED'})),
    nationality: Yup.string().required(intl.formatMessage({id: 'ERROR.REQUIRED'})),
    emergency_name: Yup.string().optional(),
    emergency_relation: Yup.string().optional(),
    emergency_tel: Yup.string().optional(),
    job: Yup.string().optional(),
    education: Yup.string().optional(),
    conditions: Yup.array()
      .min(1, intl.formatMessage({id: 'ERROR.REQUIRED'}))
      .required(intl.formatMessage({id: 'ERROR.REQUIRED'})),
  })

export const initialUser = {
  salutation: '',
  first_name: '',
  last_name: '',
  street: '',
  zip: '',
  state: '',
  country: '',
  email: '',
  phone: '',
  mobilePhone: '',
  birthday: '',
  lang_levels: {},
  languages: [],
  lang_communication: '',
  nationality: '',
  emergency_name: '',
  emergency_relation: '',
  emergency_phone: '',
  job: '',
  education: '',
  conditions: [],
}

export const extraSchema = (intl: any) =>
  Yup.object().shape({
    club: Yup.string().optional(),
    club_other: Yup.string().optional(),
    boat_license: Yup.string().optional(),
    drivers_license: Yup.array().optional(),
    size: Yup.string().required(intl.formatMessage({id: 'ERROR.REQUIRED'})),
    skills_special: Yup.array().optional(),
    skills_other: Yup.string().optional(),
  })

export const extraInitials = {
  club: '',
  club_other: '',
  boat_license: '',
  drivers_license: [],
  size: '',
  overnight: '',
  tent: '',
  skills_special: [],
  skills_other: '',
  // application_areas: [],
  application_areas_remarks: '',
  application_areas_so_far: '',
  events_so_far: '',
}
