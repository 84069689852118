import {FC} from 'react'
import {useState} from 'react'
import {UserModel} from '../../modules/auth/models/UserModel'
import {shallowEqual, useDispatch, useSelector} from 'react-redux'
import {RootState} from '../../../setup'
import {Formik, Form} from 'formik'
import {PageTitle} from '../../../_metronic/layout/core'
import {ProfileDetails} from '../../modules/accounts/components/settings/cards/ProfileDetails'
import {PageCard} from '../_components/cards'
import {AvatarPicker} from '../_components/uppy.avatar'
import {postUserUpdate} from '../_CRUD'
import * as auth from '../../modules/auth/redux/AuthRedux'
import {errorSwal, successSwal} from '../_components/swal'
import {profileDetailsSchema, initialUser} from '../_INITIALS'
import {LoadSpan} from '../_components/spans'
import {useIntl} from 'react-intl'
import {useNavigate} from 'react-router-dom'

const ProfilePageWrapper: FC = () => {
  const intl = useIntl()
  const [loading, setLoading] = useState(false)

  const user: UserModel = useSelector<RootState>(({auth}) => auth.user, shallowEqual) as UserModel
  const dispatch = useDispatch()

  const navigate = useNavigate()
  return (
    <>
      <PageTitle breadcrumbs={[]}>{intl.formatMessage({id: 'MENU.PROFILE'})}</PageTitle>
      <PageCard>
        <div className='row mx-4'>
          <label className='col-md-2 col-form-label fw-bold fs-6'>
            {intl.formatMessage({id: 'PROFILE.PICTURE'})}:
          </label>
          <div className='col-md-10 mb-6'>
            <AvatarPicker user={user} />
          </div>
        </div>
        <Formik
          initialValues={{...initialUser, ...user}}
          validationSchema={profileDetailsSchema(intl)}
          onSubmit={(values) => {
            setLoading(true)
            // console.log(values)
            postUserUpdate(values)
              .then((res) => {
                dispatch(auth.actions.setUser(values))

                successSwal(
                  intl.formatMessage(
                    {id: 'GENERAL.SUCCESSFUL_X'},
                    {action: intl.formatMessage({id: 'GENERAL.SAVED'})}
                  )
                ).then(() => navigate('/extra'))
              })
              .catch((e) => errorSwal(e)) //TODO if server return 500. No exception is thrown. Bug?
            setLoading(false)
          }}
        >
          <Form>
            <ProfileDetails />

            <div className='d-flex justify-content-end'>
              <button type='submit' className='btn btn-primary' disabled={loading}>
                {!loading && intl.formatMessage({id: 'GENERAL.CONTINUE'})}
                {loading && <LoadSpan />}
              </button>
            </div>
          </Form>
        </Formik>
      </PageCard>
    </>
  )
}

export default ProfilePageWrapper
