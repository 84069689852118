import {Modal} from 'react-bootstrap'
import {KTSVG} from '../../../_metronic/helpers'

export const CustomModal = ({
  children,
  show,
  handleClose,
  submitFormId,
  title = null,
  btn_loading = false,
  footer = (
    <>
      <button type='button' className='btn btn-light-primary' onClick={handleClose}>
        Abbrechen
      </button>
      <button
        id='submit'
        type='submit'
        form={submitFormId}
        className='btn btn-primary'
        disabled={btn_loading}
      >
        {btn_loading ? (
          <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
        ) : (
          <></>
        )}{' '}
        OK
      </button>
    </>
  ),
}: any) => {
  return (
    <>
      <Modal
        className='modal fade'
        id='kt_modal_aez'
        data-backdrop='static'
        tabIndex={-1}
        role='dialog'
        show={show}
        dialogClassName='modal-lg'
        aria-hidden='true'
        backdrop='static'
        onHide={handleClose}
      >
        <div className='modal-content'>
          <div className='modal-header'>
            <h5 className='modal-title'>{title}</h5>

            <div
              className='btn btn-icon btn-sm btn-active-light-primary ms-2'
              onClick={handleClose}
            >
              <KTSVG path='/media/icons/duotune/arrows/arr061.svg' className='svg-icon-2x' />
            </div>
          </div>
          <div className='modal-body'>{children}</div>
          <div className='modal-footer'>{footer}</div>
        </div>
      </Modal>
    </>
  )
}
