import axios from 'axios'
// import { AuthModel } from '../models/AuthModel'
import {UserModel} from '../models/UserModel'

export const GET_USER_BY_ACCESSTOKEN_URL = `/api/user/by-token`
export const LOGIN_URL = `/api/auth/login`
export const LOGOUT_URL = `/api/auth/logout`
export const REGISTER_URL = `/api/auth/register`
export const FORGOT_PASSWORD_URL = `/api/auth/forgot-password`
export const CHECK_PIN_URL = `/api/auth/check-pin`
export const RESET_PASSWORD_URL = `/api/auth/reset-password`

// Server should return AuthModel
export function login(email: string, bp: string = '') {
  return axios.post(LOGIN_URL + '/' + bp, {email})
}

export function logout() {
  return axios.post(LOGOUT_URL)
}

// Server should return AuthModel
// export function register(
//   email: string,
//   firstname: string,
//   lastname: string,
//   password: string,
//   password_confirmation: string
// ) {
//   return axios.post<AuthModel>(REGISTER_URL, {
//     email,
//     firstname,
//     lastname,
//     password,
//     password_confirmation,
//   })
// }

// Server should return object => { result: boolean } (Is Email in DB)
// export function requestPassword(email: string) {
//   return axios.post<{ result: boolean }>(FORGOT_PASSWORD_URL, { email })
// }

export function checkPin(token: string) {
  return axios.post(CHECK_PIN_URL, {token})
}

// export function resetPassword(password: string) {
//   return axios.post(RESET_PASSWORD_URL, { password })
// }

export function getUserByToken() {
  // Authorization head should be fulfilled in interceptor.
  // Check common redux folder => setupAxios
  return axios.get<UserModel>(GET_USER_BY_ACCESSTOKEN_URL)
}
