/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {useState} from 'react'
import * as Yup from 'yup'
import clsx from 'clsx'
import {Link, useNavigate, useParams} from 'react-router-dom'
import {useFormik} from 'formik'
import {login} from '../redux/AuthCRUD'
// import {toAbsoluteUrl} from '../../../../_metronic/helpers'

import {useIntl} from 'react-intl'
import {LoadSpan} from '../../../pages/_components/spans'
import {useQueryClient} from 'react-query'

// TODO intl
const loginSchema = Yup.object().shape({
  email: Yup.string()
    .email('Invalide Email addresse')
    .min(3, 'Minimum 3 Symbole')
    .max(50, 'Maximum 50 Symbole')
    .required('Email ist benötigt'),
})

export function Login() {
  const intl = useIntl()
  const params = useParams()
  const navigate = useNavigate()
  const queryClient = useQueryClient()

  const [loading, setLoading] = useState(false)

  const initialValues = {
    email: params.email
      ? params.email
      : process.env.NODE_ENV === 'development'
      ? 'dh@blassl.de'
      : '',
  }

  const formik = useFormik({
    initialValues: initialValues,
    validationSchema: loginSchema,
    onSubmit: (values, {setStatus, setSubmitting}) => {
      setLoading(true)
      setTimeout(() => {
        login(values.email, params.bp)
          .then(({data}) => {
            queryClient.removeQueries()
            setLoading(false)
            navigate('/auth/pin-input')
          })
          .catch(() => {
            setLoading(false)
            setSubmitting(false)
            setStatus(intl.formatMessage({id: 'AUTH.VALIDATION.INVALID_LOGIN'}))
          })
      }, 1000)
    },
  })

  return (
    <form
      className='form w-100'
      onSubmit={formik.handleSubmit}
      noValidate
      id='kt_login_signin_form'
    >
      {/* begin::Heading */}
      <div className='text-center mb-10'>
        <h1 className='text-dark mb-3'>{intl.formatMessage({id: 'AUTH.LOGIN'})}</h1>
        <div className='text-gray-400 fw-bold fs-4'>
          {intl.formatMessage({id: 'AUTH.LOGIN.NEW_HERE'})}{' '}
          <Link to='/auth/registration' className='link-primary fw-bolder'>
            {intl.formatMessage({id: 'AUTH.LOGIN.REGISTER'})}
          </Link>
        </div>
      </div>
      {/* begin::Heading */}

      {formik.status !== undefined && (
        <div className='mb-lg-15 alert alert-danger'>
          <div className='alert-text font-weight-bold'>{formik.status}</div>
        </div>
      )}

      {/* begin::Form group */}
      <div className='fv-row mb-10'>
        <label className='form-label fs-6 fw-bolder text-dark'>
          {intl.formatMessage({id: 'GENERAL.CONTACT.EMAIL'})}
        </label>
        <input
          placeholder='Email'
          {...formik.getFieldProps('email')}
          className={clsx(
            'form-control form-control-lg form-control-solid',
            {'is-invalid': formik.touched.email && formik.errors.email},
            {'is-valid': formik.touched.email && !formik.errors.email}
          )}
          type='email'
          name='email'
          autoComplete='off'
        />
        {formik.touched.email && formik.errors.email && (
          <div className='fv-plugins-message-container'>
            <span role='alert'>{formik.errors.email}</span>
          </div>
        )}
      </div>
      {/* end::Form group */}

      {/* begin::Action */}
      <div className='text-center'>
        <button
          type='submit'
          id='kt_sign_in_submit'
          className='btn btn-lg btn-primary w-100 mb-5 '
          disabled={formik.isSubmitting || !formik.isValid}
        >
          {/* TODO login erst aktivieren sobald config geladen wurde */}
          {!loading && (
            <span className='indicator-label'>{intl.formatMessage({id: 'GENERAL.CONTINUE'})}</span>
          )}
          {loading && <LoadSpan />}
        </button>
      </div>
      {/* end::Action */}
    </form>
  )
}
