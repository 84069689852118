import React, {MutableRefObject, useRef, useState} from 'react'
import {LinkContainer} from 'react-router-bootstrap'
import {Navigate} from 'react-router-dom'
import {checkPin} from '../redux/AuthCRUD'
import * as auth from '../redux/AuthRedux'

import {useIntl} from 'react-intl'
import {useDispatch} from 'react-redux'
import {LoadSpan} from '../../../pages/_components/spans'

export function PinInput() {
  const intl = useIntl()

  const [loading, setLoading] = useState(false)
  const [hasErrors, setHasErrors] = useState<boolean | undefined>(undefined)

  const dispatch = useDispatch()

  const pins = [
    useRef<HTMLInputElement>() as MutableRefObject<HTMLInputElement>,
    useRef<HTMLInputElement>() as MutableRefObject<HTMLInputElement>,
    useRef<HTMLInputElement>() as MutableRefObject<HTMLInputElement>,
    useRef<HTMLInputElement>() as MutableRefObject<HTMLInputElement>,
    useRef<HTMLInputElement>() as MutableRefObject<HTMLInputElement>,
    useRef<HTMLInputElement>() as MutableRefObject<HTMLInputElement>,
  ]

  const findIndex = () => pins.findIndex((el) => (el.current ? el.current.value === '' : false))

  const handleInput = (event: any) => {
    // Falls ein paste einfach weiter passen and das onPaste event
    if ((event.ctrlKey || event.metaKey) && event.keyCode === 86) return

    // prev löschen bei backspace
    if (event.keyCode === 8) {
      let index = findIndex() - 1
      if (index >= 0 && index < pins.length) pins[index].current.value = ''
    } else if (event.keyCode < 48 || event.keyCode > 57) event.preventDefault()
  }

  const handleSelect = () => {
    let index = findIndex()
    if (index !== -1) pins[index].current.focus()
  }

  const insertPaste = (event: any) => {
    let index = findIndex()
    let data = event.clipboardData.getData('Text').replace(/\D/g, '')

    pins.slice(index).forEach((el, index) => {
      if (data[index]) el.current.value = data[index]
    })

    pins[pins.length - 1].current.focus()

    event.preventDefault()
  }

  const PinInput = ({index, autoFocus = false}: any) => (
    <input
      ref={pins[index]}
      className='form-control form-control-lg form-control-solid p-1 m-1'
      style={{width: '3em', textAlign: 'center'}}
      type='tel'
      name={`pincode-${index}`}
      maxLength={1}
      tabIndex={index + 1}
      placeholder='·'
      autoComplete='off'
      autoFocus={autoFocus}
      onPaste={(event) => insertPaste(event)}
      onFocus={() => handleSelect()}
      onKeyDown={(event) => handleInput(event)}
      onKeyUp={() => handleSelect()}
    />
  )

  const sendPin = () => {
    setLoading(true)
    checkPin(pins.map((el) => el.current.value).join(''))
      .then(({data: {accessToken}}) => dispatch(auth.actions.login(accessToken)))
      .catch(() => {
        setHasErrors(true)
        setLoading(false)
      })
  }

  return (
    <>
      <div
        className='form w-100 fv-plugins-bootstrap5 fv-plugins-framework'
        id='kt_login_password_reset_form'
      >
        <div className='text-center mb-10'>
          {/* begin::Title */}
          <h1 className='text-dark mb-3'>{intl.formatMessage({id: 'AUTH.PIN.INPUT'})}</h1>
          {/* end::Title */}

          {/* begin::Link */}
          <div className='text-gray-400 fw-bold fs-4'>
            {intl.formatMessage({id: 'AUTH.PIN.QUESTION'})}
          </div>
          {/* end::Link */}
        </div>

        {/* begin::Title */}
        {hasErrors === true && (
          <div className='mb-lg-15 alert alert-danger'>
            <div className='alert-text font-weight-bold'>
              {intl.formatMessage({id: 'ERROR.DETECTED'})}
            </div>
          </div>
        )}

        {hasErrors === false && <Navigate to='/start' />}
        {/* end::Title */}

        {/* begin::Form group */}
        <div className='fv-row mb-10'>
          <div className='d-flex flex-row justify-content-center'>
            <PinInput index={0} autoFocus={true} />
            <PinInput index={1} />
            <PinInput index={2} />
            <PinInput index={3} />
            <PinInput index={4} />
            <PinInput index={5} />
          </div>
        </div>
        {/* end::Form group */}

        {/* begin::Form group */}
        <div className='d-flex flex-wrap justify-content-between'>
          <LinkContainer to='/auth/login'>
            <button
              type='button'
              id='kt_login_password_reset_form_cancel_button'
              className='btn btn-lg btn-light-primary fw-bolder flex-fill'
            >
              {intl.formatMessage({id: 'GENERAL.BACK'})}
            </button>
          </LinkContainer>
          <div style={{width: '1em'}}></div>
          <button
            onClick={() => sendPin()}
            id='kt_password_reset_submit'
            className='btn btn-lg btn-primary fw-bolder me-4 flex-fill'
            disabled={loading ? true : false}
          >
            {loading && <LoadSpan />}
            {!loading && (
              <span className='indicator-label'>
                {intl.formatMessage({id: 'GENERAL.CONTINUE'})}
              </span>
            )}
          </button>
        </div>
        {/* end::Form group */}
      </div>
    </>
  )
}
