import {Field, useFormikContext} from 'formik'
import {LoadSpan} from './spans'
import Select from 'react-select'

import MobileDatePicker from '@mui/lab/MobileDatePicker'
// import MobileTimePicker from '@mui/lab/MobileTimePicker'

import type {} from '@mui/lab/themeAugmentation'

export const CustomCheckGroup = ({...props}: any) => {
  const formik = useFormikContext()

  return (
    <CustomBaseInput formik={formik} {...props}>
      <div className='d-flex flex-column pt-2'>
        {props.options.map((el: any) => (
          <div className='form-check form-check-custom form-check-solid form-check-sm mx-4 mb-2'>
            <label>
              <Field
                name={props.name}
                type={props.tag}
                className='form-check-input'
                value={el.value}
              />
              <span className='mx-2'>{el.label}</span>
            </label>
          </div>
        ))}
      </div>
    </CustomBaseInput>
  )
}

export const CustomDatePicker = ({...props}: any) => {
  const formik = useFormikContext()

  return (
    <MobileDatePicker
      value={(formik.values as any)[props.name]}
      onChange={(date: any) => formik.setFieldValue(props.name, date)}
      onClose={() => formik.setFieldTouched(props.name)}
      {...props}
      renderInput={({inputRef, inputProps, InputProps}) => {
        return (
          <CustomBaseInput formik={formik} {...props}>
            <Field
              ref={inputRef}
              type='text'
              className='form-control form-control-solid'
              {...inputProps}
              {...props}
            />
          </CustomBaseInput>
        )
      }}
    />
  )
}

// export const CustomTimePicker = ({formik, id, label, required}: any) => {
//   return (
//     <MobileTimePicker
//       value={formik.values[id]}
//       label={label}
//       onChange={(date: any) => formik.setFieldValue(id, date._d)}
//       onClose={() => formik.setFieldTouched(id)}
//       minutesStep={5}
//       renderInput={({inputRef, inputProps, InputProps}) => {
//         return (
//           <CustomBaseInput
//             label={label}
//             id={id}
//             required={required}
//             imgPrefix={
//               <KTSVG path='/media/icons/duotune/general/gen013.svg' className='svg-icon-1x' />
//             }
//             formik={formik}
//             children={
//               <Field
//                 ref={inputRef}
//                 name={label}
//                 type='text'
//                 className='form-control'
//                 {...inputProps}
//               />
//             }
//           />
//         )
//       }}
//     />
//   )
// }

const CustomBaseInput = ({formik, ...props}: any) => {
  let error = Boolean(formik.touched[props.name] && formik.errors[props.name])

  return (
    <>
      <div className='d-flex flex-row justify-content-between'>
        <label className={'form-label ' + (props.required ? 'required' : '')}>{props.label}:</label>

        {error && <label style={{color: 'red'}}>{formik.errors[props.name]}</label>}
        {props.isLoading && <LoadSpan />}
      </div>

      <div
        className={`input-group d-flex align-content-around flex-wrap border rounded ${
          error ? 'border-danger' : 'border-white'
        }`}
      >
        {props.imgPrefix ? (
          <span className='input-group-text' id='addon-wrapping'>
            {props.imgPrefix}
          </span>
        ) : null}
        {props.children}
      </div>
    </>
  )
}

const selectStyle = {
  control: (base: any) => ({
    ...base,
    width: '100%',
    padding: '0.25rem 0.5rem',
    fontWeight: '500',
    color: '#5e6278',
    backgroundColor: '#f5f8fa',
    borderRadius: '0.475rem',
    lineHeight: '1.5',
    fontSize: '1.1rem',
    border: '1px solid #f5f8fa',
    boxShadow: 'none',
    fontFamily: 'inherit',
    '&:hover': {
      borderColor: '#f5f8fa',
    },
  }),
}

export const CustomBaseFormikInput = ({field, form, ...props}: any) => {
  let error = Boolean(form.touched[field.name] && form.errors[field.name])

  return (
    <div>
      <div className='d-flex flex-row justify-content-between'>
        <label className={'form-label ' + (props.required ? 'required' : '')}>{props.label}:</label>

        {error && <label style={{color: 'red'}}>{form.errors[field.name]}</label>}
        {props.isLoading && <LoadSpan />}
      </div>

      <div
        className={`input-group d-flex align-content-around flex-wrap border rounded ${
          error ? 'border-danger' : 'border-white'
        }`}
      >
        {props.imgPrefix && (
          <span className='input-group-text' id='addon-wrapping'>
            {props.imgPrefix}
          </span>
        )}

        {(!props.tag || props.tag === 'text') && (
          <input className='form-control form-control-solid' {...field} {...props} />
        )}
        {props.tag === 'textarea' && (
          <textarea className='form-control form-control-solid' {...field} {...props} />
        )}
        {props.tag === 'select' && (
          <select className='form-control form-control-solid form-select' {...field} {...props} />
        )}
        {props.tag === 'checkbox' && (
          <select className='form-control form-control-solid form-select' {...field} {...props} />
        )}

        {!props.isLoading && props.tag === 'react-select' && (
          <Select
            className='w-100'
            defaultValue={props.options.find((option: any) => option.value === field.value)}
            options={props.options}
            onChange={({value}) => form.setFieldValue(field.name, value)} // TODO is multi? => https://gist.github.com/hubgit/e394e9be07d95cd5e774989178139ae8?permalink_comment_id=4020453#gistcomment-4020453
            onBlur={(event) => form.setFieldTouched(field.name, true)}
            styles={selectStyle}
          />
        )}
        {props.isLoading && props.tag === 'react-select' && (
          <Select className='w-100' styles={selectStyle} />
        )}
      </div>
    </div>
  )
}
