import {AxiosError} from 'axios'

const I18N_CONFIG_KEY = process.env.REACT_APP_I18N_CONFIG_KEY || 'i18nConfig'

export default function setupAxios(axios: any, store: any) {
  axios.defaults.headers.Accept = 'application/json'

  axios.interceptors.request.use(
    (config: any) => {
      // const {
      //   auth: {accessToken},
      // } = store.getState()

      // if (accessToken) {
      //   config.headers.Authorization = `Bearer ${accessToken}`
      // }

      let lang = JSON.parse(localStorage.getItem(I18N_CONFIG_KEY) as string)
      config.headers['Accept-Language'] = lang ? lang.selectedLang : 'de'

      return config
    },
    (err: any) => Promise.reject(err)
  )

  axios.interceptors.response.use(
    (response: any) => {
      // Any status code that lie within the range of 2xx cause this function to trigger
      // Do something with response data
      return response
    },
    async (error: AxiosError) => {
      // Any status codes that falls outside the range of 2xx cause this function to trigger
      // Do something with response error

      switch (error.response?.status) {
        case 403:
          // TODO link logout?
          window.location.reload()
          break
        default:
          return error
      }
    }
  )
}
