import React, {FC} from 'react'
import {useIntl} from 'react-intl'
import {PageTitle} from '../../../_metronic/layout/core'
import {StartPage} from './start.page'

const StartPageWrapper: FC = () => {
  const intl = useIntl()

  return (
    <>
      <PageTitle breadcrumbs={[]}>{intl.formatMessage({id: 'MENU.START'})}</PageTitle>
      <StartPage />
    </>
  )
}

export default StartPageWrapper
